import type { Claim } from '@/types/claim'
import { ActiveClaimStatuses, ClosedClaimStatuses } from '@/types/claim'
import type { ProductType } from '@/types/productTypes'
import { brokerIsStroobants } from '@/utils/brokers'
import type { RequestState } from '@/utils/restStore'
import { RequestStatus, handleRequest, initialRequestState } from '@/utils/restStore'
import { defineStore } from 'pinia'

import Api from '@/services/api'

export const MINIMUM_YEAR_FOR_STROOBANTS_CLAIMS = 2024

interface State {
  claims: RequestState<Claim[]>
  selectedStatus: ClaimsTabs
  selectedContractNumber: string | null
}

export enum ClaimsTabs {
  Active = 'active',
  Closed = 'closed'
}

interface ClaimContract {
  contractType: ProductType
  contractNumber: string
}

export const claimTabs = [ClaimsTabs.Active, ClaimsTabs.Closed]

const initialState = (): State => ({
  claims: initialRequestState(),
  selectedStatus: ClaimsTabs.Active,
  selectedContractNumber: null
})

export const useClaimsStore = defineStore({
  id: 'claims',
  state: initialState,
  actions: {
    selectStatus(status: ClaimsTabs) {
      this.selectedStatus = status
    },
    selectContract(contractNumber: string | null) {
      this.selectedContractNumber = contractNumber
    },
    async fetchClaims() {
      await handleRequest(Api.getClaims, (claims: RequestState<Claim[]>) => {
        this.claims = claims
      })
      if (this.claims.status === RequestStatus.RequestLoaded && brokerIsStroobants()) {
        this.claims.data = this.claims.data.filter((claim) => {
          const year = Number(claim.reportingDate.split('/')[2])
          return year >= MINIMUM_YEAR_FOR_STROOBANTS_CLAIMS
        })
      }
    },
    async fetchCompanyClaims(companyId: string) {
      await handleRequest(
        () => Api.getCompanyClaims(companyId),
        (claims: RequestState<Claim[]>) => {
          this.claims = claims
        }
      )
      if (this.claims.status === RequestStatus.RequestLoaded && brokerIsStroobants()) {
        this.claims.data = this.claims.data.filter((claim) => {
          const year = Number(claim.reportingDate.split('/')[2])
          return year >= MINIMUM_YEAR_FOR_STROOBANTS_CLAIMS
        })
      }
    }
  },
  getters: {
    contractsWithClaims: (state: State): ClaimContract[] => {
      if (state.claims.status !== RequestStatus.RequestLoaded) {
        return []
      }
      const contracts = state.claims.data.reduce((contracts: ClaimContract[], claim) => {
        if (!contracts.some((contract) => contract.contractNumber === claim.contractNumber)) {
          contracts.push({ contractType: claim.contractType, contractNumber: claim.contractNumber })
        }
        return contracts
      }, [])

      return contracts
    },
    activeClaims: (state: State): Claim[] => {
      if (state.claims.status !== RequestStatus.RequestLoaded) {
        return []
      }
      const activeClaims = state.claims.data.filter((claim) => ActiveClaimStatuses.includes(claim.status))
      if (state.selectedContractNumber) {
        return activeClaims.filter((claim) => claim.contractNumber === state.selectedContractNumber)
      }
      return activeClaims
    },
    closedClaims: (state: State): Claim[] => {
      if (state.claims.status !== RequestStatus.RequestLoaded) {
        return []
      }
      const closedClaims = state.claims.data.filter((claim) => ClosedClaimStatuses.includes(claim.status))
      if (state.selectedContractNumber) {
        return closedClaims.filter((claim) => claim.contractNumber === state.selectedContractNumber)
      }
      return closedClaims
    }
  }
})
