import type { ProductType } from '@/types/productTypes'

export interface Claim {
  aliases: string[]
  claimId: string
  contractNumber: string
  contractType: ProductType
  references: string[]
  settlementAmount: number | null
  status: ClaimStatuses
  type: string
  statusDate: string
  expertiseDispute: boolean
  legalChallenge: boolean
  liabilityDispute: boolean
  insurer: string
  reportingDate: string
  incidentDate: string
  licencePlate: string | null
  riskObjects: RiskObjectCovers[]
  documents: ClaimDocument[]
  reportResponses: ClaimReportResponse[]
  materialDamageDeductible: number | null
  theftDeductible: number | null
}

export interface RiskObjectCovers {
  reference: string
  label: string
  covers: string[]
}

export interface ClaimDocument {
  addedAt: string
  documentType: ClaimDocumentType
  url: string
}

export enum ClaimDocumentType {
  RepairQuote = 'repair_quote_document',
  Damage = 'damage_document'
}

export enum ClaimStatuses {
  Closed = 'closed',
  Voided = 'voided',
  Reported = 'reported',
  Analysis = 'analysis',
  AwaitingExpertise = 'awaiting_expertise',
  ExpertiseDispute = 'expertise_dispute',
  AwaitingReport = 'awaiting_report',
  AwaitingLiability = 'awaiting_liability',
  LiabilityDispute = 'liability_dispute',
  LegalChallenge = 'legal_challenge',
  AwaitingClosure = 'awaiting_closure',
  InProgress = 'in_progress'
}

export const ActiveClaimStatuses = [
  ClaimStatuses.Reported,
  ClaimStatuses.Analysis,
  ClaimStatuses.AwaitingExpertise,
  ClaimStatuses.ExpertiseDispute,
  ClaimStatuses.AwaitingReport,
  ClaimStatuses.AwaitingLiability,
  ClaimStatuses.LiabilityDispute,
  ClaimStatuses.LegalChallenge,
  ClaimStatuses.AwaitingClosure,
  ClaimStatuses.InProgress
]

export const ClosedClaimStatuses = [ClaimStatuses.Closed, ClaimStatuses.Voided]

export interface OptionalStatuses {
  expertiseDispute: boolean
  legalChallenge: boolean
  liabilityDispute: boolean
}

export interface ClaimReportResponse {
  answer: string
  question: string
}
