import { DEFAULT_LOCALE, SUPPORTED_LOCALES } from '@/i18n/main'
import type { Locale } from '@/i18n/main'
import { useAuthStore } from '@/stores/auth'
import { createRouter, createWebHistory } from 'vue-router'

import Api, { visitPage } from '@/services/api'

export enum SubDomain {
  Stroobants = 'stroobants'
}

const createRouterWithI18n = (i18n: any) => {
  const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    scrollBehavior() {
      // always scroll to top
      // note that this scroll behavior will have an effect on the #app but not the other components (see AppStructure for app container scroll logic)
      return { top: 0 }
    },
    routes: [
      //* authentication pages - START
      {
        path: '/:locale/:subdomain?',
        name: 'home',
        component: () => import('../views/HomeView.vue')
      },
      {
        path: '/:locale/:subdomain?' + i18n.global.t('paths.login'),
        name: 'login',
        component: () => import('../views/LoginView.vue'),
        meta: { public: true, requiresUnauthenticated: true }
      },
      {
        path: '/:locale/:subdomain?' + i18n.global.t('paths.registration'),
        name: 'registration',
        component: () => import('../views/RegistrationView.vue'),
        meta: { public: true, requiresUnauthenticated: true }
      },
      {
        path: '/:locale/:subdomain?' + i18n.global.t('paths.password_registration') + '/:token',
        name: 'password_registration',
        component: () => import('../views/PasswordRegistrationView.vue'),
        meta: { public: true, requiresUnauthenticated: true }
      },
      //* authentication pages - END

      //* contracts related -- START
      {
        path: '/:locale/:subdomain?' + i18n.global.t('paths.contracts'),
        name: 'contracts',
        component: () => import('../views/ContractsView.vue')
      },
      {
        path: '/:locale/:subdomain?' + i18n.global.t('paths.add_contract'),
        name: 'add_contract',
        component: () => import('../views/AddContractView.vue')
      },
      {
        path: '/:locale/:subdomain?' + i18n.global.t('paths.contracts') + '/:id',
        name: 'contract_details',
        component: () => import('../views/ContractDetailsView.vue')
      },
      {
        path: '/:locale/:subdomain?' + i18n.global.t('paths.contracts') + '/:id' + i18n.global.t('paths.documents'),
        name: 'contract_documents',
        component: () => import('../views/ContractDocumentsView.vue')
      },
      {
        path: '/:locale/:subdomain?' + i18n.global.t('paths.contracts') + '/:id' + i18n.global.t('paths.covers'),
        name: 'covers',
        component: () => import('../views/ContractCoversView.vue')
      },
      {
        path: '/:locale/:subdomain?' + i18n.global.t('paths.contracts') + '/:id' + i18n.global.t('paths.invoices'),
        name: 'invoices',
        component: () => import('../views/ContractInvoiceView.vue')
      },
      {
        path:
          '/:locale/:subdomain?' +
          i18n.global.t('paths.contracts') +
          '/:id' +
          i18n.global.t('paths.contracts_and_amendments'),
        name: 'contracts_and_amendments',
        component: () => import('../views/ContractsAmendmentsView.vue')
      },
      {
        path:
          '/:locale/:subdomain?' +
          i18n.global.t('paths.contracts') +
          '/:id' +
          i18n.global.t('paths.insurance_certificates'),
        name: 'insurance_certificates',
        component: () => import('../views/InsuranceCertificatesView.vue')
      },
      //* contracts related -- END

      //* account related -- START
      {
        path: '/:locale/:subdomain?' + i18n.global.t('paths.account'),
        name: 'account',
        component: () => import('../views/AccountView.vue')
      },
      {
        path: '/:locale/:subdomain?' + i18n.global.t('paths.account') + i18n.global.t('paths.informations'),
        name: 'account_informations',
        component: () => import('../views/InformationsView.vue')
      },
      {
        path: '/:locale/:subdomain?' + i18n.global.t('paths.account') + i18n.global.t('paths.my_documents'),
        name: 'account_documents',
        component: () => import('../views/DocumentsView.vue')
      },
      {
        path: '/:locale/:subdomain?' + i18n.global.t('paths.account') + i18n.global.t('paths.signatures'),
        name: 'account_signatures',
        component: () => import('../views/DocumentsToSign.vue')
      },
      {
        path: '/:locale/:subdomain?' + i18n.global.t('paths.my_claims'),
        name: 'claims',
        component: () => import('../views/ClaimsView.vue')
      },
      {
        path: '/:locale/:subdomain?' + i18n.global.t('paths.account') + i18n.global.t('paths.referral'),
        name: 'account_referral',
        component: () => import('../views/ReferralView.vue')
      },
      {
        path: '/:locale/:subdomain?' + i18n.global.t('paths.account') + i18n.global.t('paths.legales'),
        name: 'legales',
        component: () => import('../views/LegalesView.vue')
      },
      //* account related -- END
      {
        path: '/:locale/:subdomain?' + i18n.global.t('paths.my_certificates'),
        name: 'my_certificates',
        component: () => import('../views/CertificatesView.vue')
      },
      {
        path: '/:locale/:subdomain?' + i18n.global.t('paths.online_signature'),
        name: 'online_signature',
        component: () => import('../views/OnlineSignatureView.vue')
      },
      {
        path: '/:locale/:subdomain?' + i18n.global.t('paths.my_documents'),
        name: 'my_documents',
        component: () => import('../views/DocumentsView.vue')
      },
      {
        path: '/:locale/:subdomain?' + i18n.global.t('paths.claim_report'),
        name: 'claim_report',
        component: () => import('../views/ClaimReportView.vue')
      },
      {
        path: '/:locale/:subdomain?' + i18n.global.t('paths.contract_modification'),
        name: 'contract_modification',
        component: () => import('../views/ContractModificationView.vue')
      },
      {
        path: '/:locale/:subdomain?' + i18n.global.t('paths.advisor'),
        name: 'advisor',
        component: () => import('../views/AdvisorView.vue')
      },
      {
        path: '/:locale/:subdomain?' + i18n.global.t('paths.life_procedure') + '/:id',
        name: 'life_procedure',
        component: () => import('../views/LifeProcedureView.vue')
      },
      {
        path: '/:locale/:subdomain?' + i18n.global.t('paths.notifications'),
        name: 'notifications',
        component: () => import('../views/NotificationsView.vue')
      },
      {
        path: '/:locale/:subdomain?' + i18n.global.t('paths.claim_details') + '/:id',
        name: 'claim_details',
        component: () => import('../views/ClaimDetailsView.vue')
      },
      {
        path: '/:locale/:subdomain?' + i18n.global.t('paths.claim_declaration') + '/:id',
        name: 'claim_declaration',
        component: () => import('../views/ClaimDeclarationView.vue')
      },
      {
        path: '/:locale/:subdomain?' + '/:pathMatch(.*)*',
        redirect: () => {
          //* redirect to home for other paths
          return 'home'
        }
      }
    ]
  })

  // Navigation guards
  router.beforeEach(async (to, _from, next) => {
    // Store operator_id if present in query
    if (to.query?.operator_id) {
      localStorage.setItem('operatorId', to.query.operator_id as string)
    }

    const { locale, subdomain } = to.params
    const paramsLocale = localStorage.getItem('i18n-locale') || locale || DEFAULT_LOCALE
    const appElement = document.getElementById('app')

    // Validate and enforce locale
    if (!SUPPORTED_LOCALES.includes(locale as Locale)) {
      return next({
        name: to.name || 'home',
        params: { ...to.params, locale: DEFAULT_LOCALE }
      })
    }

    // Handle subdomain preservation
    if (!subdomain && _from.params.subdomain) {
      return next({
        ...to,
        params: { ...to.params, subdomain: _from.params.subdomain }
      })
    }

    // Validate subdomain
    if (subdomain && subdomain !== SubDomain.Stroobants) {
      return next({ name: 'home', params: { locale } })
    }

    // Update subdomain-specific styling
    if (appElement) {
      const className = SubDomain.Stroobants
      if (subdomain === className) {
        appElement.classList.add(className)
      } else {
        appElement.classList.remove(className)
      }
    }

    const authStore = useAuthStore()

    // Check authentication status if unknown
    if (authStore.isUnknown) {
      try {
        const authenticated = await Api.authenticated()
        authStore.setAuthenticated(authenticated)
      } catch (error) {
        console.error('Error during authentication check:', error)
      }
    }

    // Log visited page if authenticated
    if (authStore.isAuthenticated) {
      try {
        await visitPage(to.fullPath)
      } catch (error) {
        console.error('Error when sending the page visited event:', error)
      }
    }

    // Redirect to login for non-public routes if not authenticated
    if (!to.meta.public && !authStore.isAuthenticated) {
      if (to.name) {
        localStorage.setItem('nextRouteName', to.name as string)
        localStorage.setItem('nextRouteLocale', locale as string)
        if (to.params.subdomain) {
          const subdomain = to.params.subdomain
          localStorage.setItem('nextSubdomain', subdomain as SubDomain)
        }
      }
      return next({ name: 'login', params: { locale: paramsLocale, subdomain: to.params.subdomain } })
    }

    // Redirect to stored route after login
    const nextRouteName = localStorage.getItem('nextRouteName')
    if (authStore.isAuthenticated && nextRouteName) {
      const nextLocale = localStorage.getItem('nextRouteLocale')
      const nextSubdomain = localStorage.getItem('nextSubdomain')
      localStorage.removeItem('nextRouteName')
      localStorage.removeItem('nextRouteLocale')
      localStorage.removeItem('nextSubdomain')
      return next({ name: nextRouteName, params: { locale: nextLocale, subdomain: nextSubdomain } })
    }

    // Redirect authenticated users to home if no route name or path
    if (authStore.isAuthenticated && !to.name && to.path === '/') {
      return next({ name: 'home', params: { locale: paramsLocale } })
    }

    // Redirect authenticated users away from unauthenticated routes
    if (to.meta.requiresUnauthenticated && authStore.isAuthenticated) {
      return next({ name: 'home', params: { locale: paramsLocale } })
    }

    // Proceed to the next route
    return next()
  })

  return router
}

export enum RouteNames {
  Notifications = 'notifications',
  AccountDocuments = 'account_documents',
  MyDocuments = 'my_documents',
  ContractDocuments = 'contract_documents'
}

export default createRouterWithI18n
