import { ClaimStatuses } from '@/types/claim'
import type { DateStr } from '@/types/general'
import type { ProductType } from '@/types/productTypes'

export interface Authenticated {
  authenticated: boolean
}

export interface MeToken {
  token: string
}

export interface Me {
  uuid: string
  firstName: string
  lastName: string
  email: string
  locale: string
  gender: string | null
  mobilePhone: string | null
  birthdate: DateStr | null
  createdAt: DateStr
  mobileDevice: boolean
  clientCardUrl: string
  pendingActions: Array<PendingAction | PendingClaim>
  otherPendingActionsCount: Number
  anyContract: boolean
  anyActiveMotorContract: boolean
  address: {
    street: string
    streetNumber: string
    zipCode: string
    city: string
    country: string
  } | null
  unfit: boolean
  onboarded: boolean
  advisor: Advisor | null
  fromBrokerId: Broker | null
}

export interface Advisor {
  firstName: string
  lastName: string
  profilePictureUrl: string
  email: string
}

export interface Company {
  advisor: Advisor | null
  anyActiveMotorContract: boolean
  anyContract: boolean
  email: string
  otherPendingActionsCount: number
  pendingActions: PendingClaim[]
  vatNumber: string
  legalName: string
  legalForm: string
}

export enum Broker {
  Marsh = 'marsh',
  Stroobants = 'stroobants_ts'
}

export interface PendingAction {
  link: string
  uuid: string
  type: string
  productType: ProductType
  title: string
}

export interface PendingClaim {
  aliases: string[]
  claimId: string
  contractNumber: string
  contractType: ProductType
  references: string[]
  settlementAmount: number | null
  status: ClaimStatuses
  type: PendingType.Claim
  statusDate: string
  incidentDate: string
  licencePlate: string | null
}

export enum PendingType {
  Claim = 'claim',
  ContractRequest = 'contract_request',
  Document = 'document',
  Signature = 'signing',
  Unpaid = 'unpaid',
  Offer = 'offer'
}

export interface Profile {
  profileType: ProfileType.B2B | ProfileType.B2C
  email: string
  firstName: string
  lastName: string
  clientId?: string
  company?: { companyId: string; legalName: string; legalForm: string }
  positions?: string[]
  roles?: MemberRole[]
  title?: string | null
}

export enum ProfileType {
  B2B = 'b2b',
  B2C = 'b2c'
}

export enum MemberRole {
  PrimaryContact = 'primary_contact_member',
  Signatory = 'signatory',
  UnpaidAlertReceiver = 'unpaid_alert_receiver'
}
