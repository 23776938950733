import { SubDomain } from '@/router/index'
import type { Advisor } from '@/types/me'

export enum Broker {
  Yago = 'Yago',
  Stroobants = 'Stroobants'
}

export const brokerIsStroobants = () => {
  return !!window.location.href.includes(SubDomain.Stroobants)
}

export const brokerName = () => {
  if (brokerIsStroobants()) return Broker.Stroobants
  else return Broker.Yago
}

export const brokerEmail = (b2bProfile?: boolean) => {
  // stroobants
  if (brokerIsStroobants()) return `hello@stroobantsassurances.be`
  // yago b2b
  else if (b2bProfile) return `josephine.decharentenay@yago.be`
  // yago b2c
  else return `hello@yago.be`
}

export const brokerTel = (b2bProfile?: boolean) => {
  // stroobants
  if (brokerIsStroobants()) return '+32 2 374 32 22'
  // yago b2b
  else if (b2bProfile) return `+32 2 588 41 65`
  // yago b2c
  else return '+32 2 808 11 78'
}

export const mailtoStroobantsAdvisor = (advisor: Advisor | null) => {
  return `mailto:${advisor?.email ? advisor.email : 'tanguy@stroobantsassurances.be'}`
}
